import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Form, InputGroup, Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { generarCuotasPorRango, generarCuotasPorRangoArq, obtenerCuotasGeneradas } from '../../reducers/cuota_slice';
import { fetchAfiliados } from '../../reducers/afiliado_slice';
import { useNavigate } from 'react-router-dom';

const GenerarCuotas = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [cuotaInicial, setCuotaInicial] = useState(`${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}`);
    const [cuotaFinal, setCuotaFinal] = useState(cuotaInicial);
    const [showConfirm, setShowConfirm] = useState(false);
    const [isIndividual, setIsIndividual] = useState(false); // Estado para el checkbox "Individual"
    const [selectedAfiliado, setSelectedAfiliado] = useState(null); // Estado para el afiliado seleccionado
    const [searchTerm, setSearchTerm] = useState(''); // Estado para el término de búsqueda
    const [filteredAfiliados, setFilteredAfiliados] = useState([]); // Estado para los afiliados filtrados
    const [showDropdown, setShowDropdown] = useState(false); // Estado para mostrar/ocultar el dropdown
    const [isGenerating, setIsGenerating] = useState(false); // Estado para indicar si se está generando
    const userId = useSelector(state => state.sesion.id);
    const afiliados = useSelector(state => state.afiliado.afiliados || []); // Acceder a afiliados desde afiliado_slice

    useEffect(() => {
        dispatch(obtenerCuotasGeneradas())
            .then((result) => {
                console.log("Resultado de dispatch en obtenerCuotasGeneradas:", result);
            })
            .catch(error => console.error("Error al despachar obtenerCuotasGeneradas:", error));

        // Obtener la lista de afiliados
        dispatch(fetchAfiliados());
    }, [dispatch]);

    // Filtrar afiliados según el término de búsqueda
    useEffect(() => {
        if (searchTerm.length > 0) {
            const filtered = afiliados.filter(afiliado => {
                const { persona } = afiliado;
                const fullName = `${persona.ci} ${persona.apellido1 || ""} ${persona.apellido2 || ""} ${persona.nombre}`.toLowerCase();
                return fullName.includes(searchTerm.toLowerCase());
            });
            setFilteredAfiliados(filtered);
            setShowDropdown(true);
        } else {
            setFilteredAfiliados([]);
            setShowDropdown(false);
        }
    }, [searchTerm, afiliados]);

    const handleGenerar = async () => {
            console.log("Entro a HandleGenerar");
        
            if (!userId) {
                Swal.fire('Error', 'No se ha identificado el usuario.', 'error');
                return;
            }
        
            if (isIndividual && !selectedAfiliado) {
                Swal.fire('Error', 'Debe seleccionar un afiliado para la generación individual.', 'error');
                return;
            }
        
            const payload = {
                cuota_inicial: cuotaInicial,
                cuota_final: cuotaFinal,
                id_usuario: userId,
                id_afiliado: isIndividual ? selectedAfiliado : null // Incluir id_afiliado si es individual
            };
        
            setIsGenerating(true); // Mostrar mensaje de "En proceso"
        
            try {
                const action = isIndividual ? generarCuotasPorRangoArq(payload) : generarCuotasPorRango(payload);
                const result = await dispatch(action).unwrap(); // Desempaqueta la promesa
        
                // Si llegamos aquí, la acción se resolvió correctamente (fulfilled)
                Swal.fire('Éxito', 'Cuotas generadas correctamente.', 'success').then(() => {
                    navigate('/admin'); // Redirigir al menú principal
                });
            } catch (error) {
                // Si llegamos aquí, la acción fue rechazada (rejected)
                Swal.fire('Error', error.payload || 'Ocurrió un error al generar las cuotas.', 'error');
            } finally {
                setIsGenerating(false); // Ocultar mensaje de "En proceso"
                setShowConfirm(false); // Cerrar el modal de confirmación
            }
        };
    const handleCancel = () => {
        const currentMonthYear = `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}`;
        setCuotaInicial(currentMonthYear);
        setCuotaFinal(currentMonthYear);
        navigate('/admin'); // Redirigir al menú principal al cancelar
    };

    // Obtener el nombre completo del afiliado seleccionado
    const getNombreAfiliado = () => {
        if (!selectedAfiliado) return '';
        const afiliado = afiliados.find(a => a.id === selectedAfiliado);
        if (!afiliado) return '';
        const { persona } = afiliado;
        return `${persona.apellido1 || ""} ${persona.apellido2 || ""}, ${persona.nombre}`;
    };
    const handleCuotaChange = (e) => {
        // Cuota formato (AAAA-MM)
        const { value, name } = e.target;
    
        // Validar que la cuotaInicial no sea menor a abril de 2025
        const minCuota = "2025-04";
    
        if (name === "cuotaInicial") {
            if (value >= minCuota) {
                setCuotaInicial(value);
    
                // Validar que la cuotaFinal no sea menor a la nueva cuotaInicial
                if (cuotaFinal < value) {
                    setCuotaFinal(value);
                    Swal.fire("Advertencia", "La cuota final no puede ser menor a la cuota inicial. Se ajustará automáticamente.", "warning");
                }
            } else {
                setCuotaInicial(minCuota);
                Swal.fire("Error en la cuota inicial", "La cuota inicial no puede ser menor a abril de 2025.", "error");
            }
        } else if (name === "cuotaFinal") {
            if (value >= cuotaInicial) {
                setCuotaFinal(value);
            } else {
                setCuotaFinal(cuotaInicial);
                Swal.fire("Error en la cuota final", "La cuota final no puede ser menor a la cuota inicial.", "error");
            }
        }
    };
    return (
        <div className="list-container list3">
            <h1 className="table-title">Generar Cuotas para un Rango Específico</h1>
            <p className="text-danger">Este proceso es delicado y su ejecución es irreversible.</p>

            <div className="list-content">
                <Form>
                    <Form.Group controlId="cuotaInicial">
                        <Form.Label>Cuota Inicial (AAAA-MM):</Form.Label>
                        <Form.Control
                            type="text"
                            name='cuotaInicial'
                            value={cuotaInicial}
                            onChange={(e) => setCuotaInicial(e.target.value)}
                            onBlur={handleCuotaChange}
                            style={{ width: '100px' }}
                        />
                    </Form.Group>

                    <Form.Group controlId="cuotaFinal">
                        <Form.Label>Cuota Final (AAAA-MM):</Form.Label>
                        <Form.Control
                            type="text"
                             name='cuotaFinal'
                            value={cuotaFinal}
                            onChange={(e) => setCuotaFinal(e.target.value)}
                            onBlur={handleCuotaChange}
                            style={{ width: '100px' }}
                        />
                    </Form.Group>

                    {/* Checkbox para seleccionar generación individual */}
                    <Form.Group controlId="isIndividual" className="mt-3">
                        <Form.Check
                            type="checkbox"
                            label="Generación Individual"
                            checked={isIndividual}
                            onChange={(e) => setIsIndividual(e.target.checked)}
                        />
                    </Form.Group>

                    {/* Mostrar el campo de búsqueda y el dropdown si el checkbox está marcado */}
                    {isIndividual && (
                        <Form.Group>
                            <Form.Label>Buscar Afiliado</Form.Label>
                            <InputGroup size="sm" style={{ width: '300px' }}> {/* Contenedor más pequeño */}
                                <Form.Control
                                    type="text"
                                    placeholder="Buscar por C.I., registro o nombre"
                                    value={searchTerm}
                                    onChange={(e) => {
                                        const term = e.target.value;
                                        setSearchTerm(term);
                                    }}
                                />
                            </InputGroup>
                            {showDropdown && (
                                <div className="dropdown-menu show" style={{ width: '300px', maxHeight: '200px', overflowY: 'auto' }}>
                                    {filteredAfiliados.map(afiliado => {
                                        const persona = afiliado.persona;
                                        return (
                                            <div
                                                key={afiliado.id}
                                                className="dropdown-item"
                                                onClick={() => {
                                                    setSelectedAfiliado(afiliado.id);
                                                    setShowDropdown(false);
                                                    setSearchTerm(`${persona.apellido1 || ""} ${persona.apellido2 || ""}, ${persona.nombre}`);
                                                }}
                                            >
                                                {`${persona.apellido1 || ""} ${persona.apellido2 || ""}, ${persona.nombre} - CI: ${persona.ci}`}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </Form.Group>
                    )}

                    <div className="list-controls mt-4">
                        <Button variant="danger" onClick={() => setShowConfirm(true)} disabled={isGenerating}>
                            Iniciar Generación de Cuotas
                        </Button>
                        <Button variant="secondary" className="ms-2" onClick={handleCancel} disabled={isGenerating}>
                            Cancelar
                        </Button>
                    </div>
                </Form>

                <Modal show={showConfirm} onHide={() => setShowConfirm(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title className="text-danger">¡Advertencia!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Este proceso generará cuotas en el rango especificado. ¿Desea continuar?</p>
                        <p className="text-danger">
                            {isIndividual ? (
                                `Se generarán cuotas para el afiliado: ${getNombreAfiliado()}`
                            ) : (
                                'Se generarán cuotas para todos los afiliados.'
                            )}
                        </p>
                        <p className="text-danger">Esta acción es irreversible. ¿Está seguro de que desea continuar?</p>
                        {isGenerating && (
                            <div className="text-center">
                                <Spinner animation="border" role="status" className="me-2" />
                                <span>Generando cuotas, por favor espere...</span>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowConfirm(false)} disabled={isGenerating}>
                            Cancelar
                        </Button>
                        <Button variant="danger" onClick={handleGenerar} disabled={isGenerating}>
                            {isGenerating ? 'Generando...' : 'Confirmar y Continuar'}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default GenerarCuotas;