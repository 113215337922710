import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal,Form, Table } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { BsCamera, BsEye, BsEyeSlash } from 'react-icons/bs';
import { fetchRecibos, addRecibo } from '../../reducers/recibo_slice';
import { fetchIngresosVarios } from '../../reducers/ingresosVarios_slice';
import { fetchPersonas } from '../../reducers/persona_slice';
import { fetchBancos } from '../../reducers/banco_slice';
import Button from 'react-bootstrap/Button';
import moment from 'moment-timezone';
import { BsPlusCircle, BsTrash } from 'react-icons/bs';

const ReciboForm = ({ handleClose }) => {
    const dispatch = useDispatch();
    const [showCameraModal, setShowCameraModal] = useState(false);
    const [nombreArchivo, setNombreArchivo] = useState('');
    const [imagenPreview, setImagenPreview] = useState(null);
    const videoRef = useRef(null);
    const [depositIndex, setDepositIndex] = useState(null);

    const ingresosVarios = useSelector(state => state.ingresosVarios.ingresosVarios || []);
    const personas = useSelector(state => state.persona.personas || []);
    const bancos = useSelector(state => state.banco.bancos || []);

    const [filteredPersonas, setFilteredPersonas] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [tipoIngreso, setTipoIngreso] = useState("Banco");

    
    const fecha = moment().tz("America/La_Paz").format('YYYY-MM-DD HH:mm:ss');
    const [detalles, setDetalles] = useState([{ cantidad: 1, concepto: '', pu: 0, monto: 0, descuento: 0, pagado: 0 }]);
    const [recibo, setRecibo] = useState({ beneficiario: '', concepto: '',concepto2: '',concepto3: '', monto: 0, ci_persona: '' });
    const [depositos, setDepositos] = useState([{ id_banco: '', monto: '', fecha_deposito: fecha, docum: '', archivo: null }]);
    const [totalDepositado, setTotalDepositado] = useState(0);

    const userId = useSelector(state => state.sesion.id);
    const xcaja = useSelector(state => state.sesion.caja);
    const xidusuario = userId;
    const xcuentaC = useSelector(state => state.sesion.cuentacaja);

    const totalPagado = detalles.reduce((sum, detalle) => sum + detalle.pagado, 0);


    useEffect(() => {
        dispatch(fetchIngresosVarios());
        dispatch(fetchPersonas());
        dispatch(fetchBancos());
    }, [dispatch]);

    useEffect(() => {
        setTotalDepositado(depositos.reduce((sum, deposito) => sum + parseFloat(deposito.monto || 0), 0));
    }, [depositos]);

    const handleSearch = (term) => {
        setSearchTerm(term);
        if (term) {
            const filtered = personas.filter(persona =>
                (persona.apellido1 && persona.apellido1.toLowerCase().includes(term.toLowerCase())) ||
                (persona.ci && persona.ci.toString().includes(term))
            );
            setFilteredPersonas(filtered);
            setShowSuggestions(true);
        } else {
            setFilteredPersonas([]);
            setShowSuggestions(false);
        }
    };
    const handleFileChange = (index, file) => {
        const updatedDepositos = [...depositos];
        updatedDepositos[index].archivo = file;
        setDepositos(updatedDepositos);
    };
    
    const handleCaptureImage = (index, event) => {
        const file = event.target.files[0];
        if (file) {
            handleFileChange(index, file);
        }
    };
    
    const handleSelectPersona = (persona) => {
        setRecibo({
            ...recibo,
            beneficiario: `${persona.apellido1 || ""} ${persona.apellido2 || ""} ${persona.nombre}`,
            ci_persona: persona.ci
        });
        setSearchTerm("");
        setShowSuggestions(false);
    };

    const handleDetailChange = (index, field, value) => {
        const updatedDetails = [...detalles];
        updatedDetails[index][field] = value;

        if (field === 'cantidad' || field === 'pu' || field === 'descuento') {
            const cantidad = parseFloat(updatedDetails[index].cantidad) || 0;
            const pu = parseFloat(updatedDetails[index].pu) || 0;
            const descuento = parseFloat(updatedDetails[index].descuento) || 0;

            updatedDetails[index].monto = cantidad * pu;
            updatedDetails[index].pagado = updatedDetails[index].monto - descuento;
        }

        setDetalles(updatedDetails);
    };

    const handleChangeDeposito = (index, field, value) => {
        const updatedDepositos = [...depositos];
        updatedDepositos[index][field] = value;
        setDepositos(updatedDepositos);
    };
    const handleAddDeposito = () => {
        const lastDeposito = depositos[depositos.length - 1];
        if (lastDeposito.id_banco && lastDeposito.monto && lastDeposito.fecha_deposito && lastDeposito.docum) {
            setDepositos([...depositos, { id_banco: '', monto: '', fecha_deposito: fecha, docum: '', archivo: null }]);
        } else {
            Swal.fire('Error', 'Debe completar todos los campos del depósito antes de agregar uno nuevo.', 'error');
        }
    };
    
    const handleRemoveDeposito = (index) => {
        if (depositos.length === 1) {
            Swal.fire('Error', 'Debe haber al menos un depósito registrado.', 'error');
            return;
        }
        const updatedDepositos = depositos.filter((_, i) => i !== index);
        setDepositos(updatedDepositos);
    };
    const addDetail = () => {
        const lastDetail = detalles[detalles.length - 1];
        if (!lastDetail.concepto || lastDetail.pu <= 0 || lastDetail.cantidad <= 0) {
            Swal.fire('Error', 'Debe completar todos los campos antes de agregar un nuevo detalle.', 'error');
            return;
        }
        setDetalles([...detalles, { cantidad: 1, concepto: '', pu: 0, monto: 0, descuento: 0, pagado: 0 }]);
    };

    const removeDetail = (index) => {
        if (detalles.length === 1) {
            Swal.fire('Error', 'Debe haber al menos un ingreso registrado.', 'error');
            return;
        }
        const updatedDetails = detalles.filter((_, i) => i !== index);
        setDetalles(updatedDetails);
    };

    const startCamera = (index) => {
        const randomNum = Math.floor(Math.random() * 1000) + 1;
        setNombreArchivo(`captura-${randomNum}.jpg`);
        setDepositIndex(index);
        setShowCameraModal(true);

        navigator.mediaDevices.getUserMedia({ video: true })
            .then((stream) => {
                videoRef.current.srcObject = stream;
                videoRef.current.play();
            })
            .catch((err) => {
                console.error("Error al acceder a la cámara: ", err);
            });
    };

    // Nueva función para alternar la visibilidad de la imagen
    const toggleImageVisibility = (index) => {
        if (depositos[index].archivo && depositos[index].archivo.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = (e) => setImagenPreview(e.target.result);
            reader.readAsDataURL(depositos[index].archivo);
        } else {
            setImagenPreview(null); // Ocultar la previsualización si no hay imagen
        }
    };

    const captureImage = () => {
        if (!nombreArchivo) {
            Swal.fire('Error', 'Debe proporcionar un nombre para el archivo antes de capturar la imagen.', 'error');
            return;
        }

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

        const dataUrl = canvas.toDataURL('image/jpeg');
        const blob = dataURLtoBlob(dataUrl);
        const file = new File([blob], nombreArchivo, { type: 'image/jpeg' });

        if (depositIndex !== null) {
            handleFileChange(depositIndex, file); // Actualiza el depósito correspondiente
        }

        setImagenPreview(dataUrl);
        setShowCameraModal(false);
    };

    const dataURLtoBlob = (dataURL) => {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    };

    const handleSubmit = async () => {
        console.log("Fecha=",fecha)
        if (!recibo.beneficiario || !recibo.concepto) {
            Swal.fire('Error', 'Debe completar los datos del recibo.', 'error');
            return;
        }

        if (tipoIngreso === "Banco" && totalDepositado !== totalPagado) {
            Swal.fire('Error', 'El total de los depósitos debe coincidir con el total de los ingresos.', 'error');
            return;
        }
        const confirm = await Swal.fire({
            title: '¿Está seguro?',
            text: 'Este proceso es irreversible . ¿Desea continuar?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, grabar'
        });
    
        if (!confirm.isConfirmed) return;
        try {
            const nroRecibo = "1";
            const concepto2 = depositos
            .map(deposito => {
                const banco = bancos.find(b => String(b.id) === String(deposito.id_banco)); // Asegurar coincidencia de tipo
                return banco ? `${banco.sigla} ${deposito.docum} ${deposito.fecha_deposito} ${deposito.monto}` : "";
            })
            .filter(texto => texto.trim() !== "") // Filtramos valores vacíos
            .join(" | "); // Unimos los depósitos con separador
        
            const xrecibo = {
                nroRecibo,
                tipo: 'INGRESO',
                fecha,
                monto: totalPagado,
                beneficiario: "Beneficiario: " + recibo.beneficiario,
                ci_persona: recibo.ci_persona,
                concepto: "Concepto: " + recibo.concepto,
                concepto2,
                concepto3: "",
                id_caja: xcaja,
                id_usuario: xidusuario,
                pago: tipoIngreso === "Caja" ? "C" : "B"
            };
            const reciboDetalles = detalles.map(detalle => ({
                id_caja: xcaja,
                nroRecibo,
                tipo: 'INGRESO',
                fecha,
                pu: detalle.pu,
                concepto: detalle.concepto,
                cantidad: detalle.cantidad,
                monto: detalle.monto,
                pagado: detalle.pagado,
                descuento: detalle.descuento,
                codigo_cuentaC: xcuentaC,
                codigo_cuentaM: detalle.codigo_cuenta,
                pago: xrecibo.pago
            }));

            const createdRecibo = await dispatch(addRecibo({ recibo: { ...xrecibo, monto: totalPagado }, reciboDetalles, depositos })).unwrap();
            Swal.fire('Éxito', `Recibo registrado correctamente. ID del Recibo: ${createdRecibo.id_recibo}`, 'success');
            handleClose(createdRecibo.id_recibo);
        } catch (error) {
            Swal.fire('Error', 'No se pudo registrar el recibo.', 'error');
        }
    };

    return (
        <>
            {/* Modal para capturar imagen */}
            {showCameraModal && (
                <Modal show={showCameraModal} onHide={() => setShowCameraModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Capturar Imagen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <video ref={videoRef} style={{ width: '100%' }} />
                        <Form.Group className="mt-3">
                            <Form.Label>Nombre del Archivo</Form.Label>
                            <Form.Control
                                type="text"
                                value={nombreArchivo}
                                onChange={(e) => setNombreArchivo(e.target.value)}
                                placeholder="Ingrese el nombre del archivo"
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowCameraModal(false)}>
                            Cerrar
                        </Button>
                        <Button variant="primary" onClick={captureImage}>
                            Capturar Imagen
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
    
            <div style={{ width: '100%', margin: '0 auto' }}>
                {/* Primera línea: Fecha, Buscar Persona y Tipo de Ingreso */}
                <div className="row mb-3">
                    <div className="col-md-4">
                        <Form.Group>
                            <Form.Label>Fecha</Form.Label>
                            <Form.Control type="text" value={fecha} readOnly />
                        </Form.Group>
                    </div>
                    <div className="col-md-4">
                        <Form.Group>
                            <Form.Label>Buscar Persona</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Buscar por Apellido1 o CI"
                                value={searchTerm}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                            {showSuggestions && (
                                <div className="suggestions-box">
                                    {filteredPersonas.map((persona) => (
                                        <div
                                            key={persona.id}
                                            className="suggestion-item"
                                            onClick={() => handleSelectPersona(persona)}
                                        >
                                            {`${persona.apellido1 || ""} ${persona.apellido2 || ""} ${persona.nombre}`} - {persona.ci}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </Form.Group>
                    </div>
                    <div className="col-md-4">
                        <Form.Group>
                            <Form.Label>Tipo de Ingreso</Form.Label>
                            <Form.Control
                                as="select"
                                value={tipoIngreso}
                                onChange={(e) => setTipoIngreso(e.target.value)}
                            >
                                <option value="Caja">Caja</option>
                                <option value="Banco">Banco</option>
                            </Form.Control>
                        </Form.Group>
                    </div>
                </div>
    
                {/* Segunda línea: Beneficiario */}
                <div className="row mb-3">
                    <div className="col-md-12">
                        <Form.Group>
                            <Form.Label>Beneficiario</Form.Label>
                            <Form.Control type="text" value={recibo.beneficiario} readOnly />
                        </Form.Group>
                    </div>
                </div>
    
                {/* Tercera línea: Concepto */}
                <div className="row mb-3">
                    <div className="col-md-12">
                        <Form.Group>
                            <Form.Label>Concepto</Form.Label>
                            <Form.Control
                                type="text"
                                value={recibo.concepto}
                                onChange={(e) => setRecibo({ ...recibo, concepto: e.target.value })}
                            />
                        </Form.Group>
                    </div>
                </div>
    
                {tipoIngreso === "Banco" && (
                    <div>
                        <h5>Registrar Depósitos</h5>
                        <Table responsive bordered>
                            <thead>
                                <tr>
                                    <th>Banco</th>
                                    <th>Monto (Bs)</th>
                                    <th>Fecha Depósito</th>
                                    <th>N° Ref. Dep.</th>
                                    <th>Foto de respaldo</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {depositos.map((deposito, index) => (
                                    <tr key={index}>
                                        <td>
                                            <Form.Control
                                                as="select"
                                                className='banco-input'
                                                value={deposito.id_banco}
                                                onChange={(e) => handleChangeDeposito(index, 'id_banco', e.target.value)}
                                            >
                                                <option value="">Seleccione un banco</option>
                                                {bancos.map((banco) => (
                                                    <option key={banco.id} value={banco.id}>{banco.sigla}</option>
                                                ))}
                                            </Form.Control>
                                        </td>
                                        <td>
                                            <Form.Control
                                                className='number-input'
                                                type="number"
                                                value={deposito.monto}
                                                onChange={(e) => handleChangeDeposito(index, 'monto', e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <Form.Control
                                                type="datetime-local"
                                                value={deposito.fecha_deposito}
                                                onChange={(e) => handleChangeDeposito(index, 'fecha_deposito', e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <Form.Control
                                                type="text"
                                                value={deposito.docum}
                                                onChange={(e) => handleChangeDeposito(index, 'docum', e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Form.Control
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) => handleFileChange(index, e.target.files[0])}
                                                />
                                                {deposito.archivo && (
                                                    <>
                                                        <span style={{ marginLeft: '10px' }}>{deposito.archivo.name}</span>
                                                        <button
                                                            className="icon-button"
                                                            style={{ marginLeft: '10px' }}
                                                            onClick={() => toggleImageVisibility(index)}
                                                            type="button"
                                                        >
                                                            {imagenPreview ? <BsEyeSlash /> : <BsEye />}
                                                        </button>
                                                    </>
                                                )}
                                                <button
                                                    className="icon-button"
                                                    style={{ marginLeft: '10px' }}
                                                    onClick={() => startCamera(index)}
                                                     type="button"
                                                >
                                                    <BsCamera />
                                                </button>
                                            </div>
                                        </td>
                                        <td>
                                            <button className="icon-button" onClick={handleAddDeposito}>
                                                <i className="bi bi-plus-circle"></i>
                                            </button>
                                            <button className="icon-button" onClick={() => handleRemoveDeposito(index)}>
                                                <i className="bi bi-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                )}
    
                <h5>Detalles del Recibo</h5>
                <Table bordered>
                    <thead>
                        <tr>
                            <th>Cantidad</th>
                            <th>Concepto</th>
                            <th>PU</th>
                            <th>Monto</th>
                            <th>Descuento</th>
                            <th>Pagado</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {detalles.map((detalle, index) => (
                            <tr key={index}>
                                <td>
                                    <Form.Control
                                        type="number"
                                        value={detalle.cantidad}
                                        onChange={(e) => handleDetailChange(index, 'cantidad', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <Form.Control
                                        as="select"
                                        value={detalle.concepto}
                                        onChange={(e) => {
                                            const selectedIngreso = ingresosVarios.find((ingreso) => ingreso.descripcion === e.target.value);
                                            if (selectedIngreso) {
                                                handleDetailChange(index, 'concepto', selectedIngreso.descripcion);
                                                handleDetailChange(index, 'codigo_cuenta', selectedIngreso.codigo_cuenta);
                                            }
                                        }}
                                    >
                                        <option value="">Seleccione Otros Ingresos</option>
                                        {ingresosVarios.map((ingreso) => (
                                            <option key={ingreso.id} value={ingreso.descripcion}>{ingreso.descripcion}</option>
                                        ))}
                                    </Form.Control>
                                </td>
                                <td>
                                    <Form.Control
                                        type="number"
                                        value={detalle.pu}
                                        onChange={(e) => handleDetailChange(index, 'pu', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <Form.Control type="text" value={detalle.monto.toFixed(2)} readOnly />
                                </td>
                                <td>
                                    <Form.Control
                                        type="number"
                                        value={detalle.descuento}
                                        onChange={(e) => handleDetailChange(index, 'descuento', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <Form.Control type="text" value={detalle.pagado.toFixed(2)} readOnly />
                                </td>
                                <td>
                                    <button className="icon-button" onClick={() => removeDetail(index)}>
                                        <i className="bi bi-trash"></i>
                                    </button>
                                    {index === detalles.length - 1 && (
                                        <button className="icon-button" onClick={addDetail}>
                                            <i className="bi bi-plus-circle"></i>
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
    
                <h5>Total: {totalPagado.toFixed(2)} Bs.</h5>
    
                <Button onClick={handleSubmit}>Registrar Recibo</Button>
            </div>
        </>
    );
    
};
export default ReciboForm;
